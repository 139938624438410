import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { AssignmentData } from "@api/assignments/models/assignment.model";
import { AssignmentsService } from "@api/assignments/services/assignments.service";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { CustomValidators } from "@modules/shared/_validators";
import { DateManagementService } from "@modules/shared/services/date-management.service";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AssignmentTypeEnum } from "src/app/_enums/assignment-type-enum";
import { User } from "src/app/_models/user";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { UsersService } from "src/app/_services/users.service";
import { AssignmentHelper } from "../../helpers/assignment.helper";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  templateUrl: "./create-assignment-modal.component.html",
  styleUrls: ["./create-assignment-modal.component.scss"],
})
export class CreateAssignmentModalComponent implements OnInit {
  public edit = false;
  public form: UntypedFormGroup;

  public assignment: AssignmentData;
  public homeCountriesFilterControl = new UntypedFormControl();
  public hostCountriesFilterControl = new UntypedFormControl();
  public expatFilterControl = new UntypedFormControl();

  public user: UserData;
  public countries;
  public filteredHomeCountries;
  public filteredHostCountries;
  public managers: User[];
  public types$: Observable<any>;
  public expats;
  public filteredExpats;
  public isLoaded = false;
  private clientId: number;

  searchHomeCitiesControl: UntypedFormControl;
  homeCities = [];

  searchHostCitiesControl: UntypedFormControl;
  hostCities = [];

  startDate;
  endDate;

  private unsubscribe$: Subject<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CreateAssignmentModalComponent>,
    private dictionariesService: DictionariesService,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private assignmentsService: AssignmentsService,
    public dateManagementService: DateManagementService,
    public assignmentHelper: AssignmentHelper
  ) {
    this.searchHomeCitiesControl = new UntypedFormControl("");
    this.searchHostCitiesControl = new UntypedFormControl("");
  }

  ngOnInit() {
    this.unsubscribe$ = new Subject();
    this.clientId = this.data.clientId;
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.user = user;
        this.getAssignments();
        this.patchForm();
        this.getCountryList();
        this.getExpats();
        this.getTypes();
        this.filterHomeCountries();
        this.filterHostCountries();
        this.filterExpats();
        this.isLoaded = true;
      });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAssignments() {
    this.assignment = this.data.assignment;
  }

  private patchForm() {
    this.edit = !!this.assignment;
    const isDisabled =
      this.assignment &&
      this.assignmentHelper.onlyDatesAreEditable(this.assignment);
    const inProgress =
      this.assignment &&
      this.assignmentHelper.onlyEndDateIsEditable(this.assignment);

    this.form = new UntypedFormGroup(
      {
        id: new UntypedFormControl({ value: null, disabled: isDisabled }),
        client_id: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        expat_id: new UntypedFormControl({ value: null, disabled: this.edit }, [
          Validators.required,
        ]),
        expat_name: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        home_country: new UntypedFormControl(
          { value: null, disabled: isDisabled },
          [Validators.required]
        ),
        home_manager_id: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        home_manager_name: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        home_manager_email: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_country: new UntypedFormControl(
          { value: null, disabled: isDisabled },
          [Validators.required]
        ),
        host_manager_id: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_manager_name: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_manager_email: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_city: new UntypedFormControl(
          { value: null, disabled: isDisabled },
          [Validators.required]
        ),
        home_city: new UntypedFormControl(
          { value: null, disabled: isDisabled },
          [Validators.required]
        ),
        host_office_id: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_office_name: new UntypedFormControl({
          value: null,
          disabled: isDisabled,
        }),
        host_parent_company: new UntypedFormControl(""),
        cost_center: new UntypedFormControl(""),
        business_line: new UntypedFormControl(""),
        number_adults: new UntypedFormControl(""),
        number_children: new UntypedFormControl(""),
        type: new UntypedFormControl({ value: null, disabled: isDisabled }, [
          Validators.required,
        ]),
        status: new UntypedFormControl({ value: null, disabled: isDisabled }),
        start_date: new UntypedFormControl(null, [Validators.required]),
        end_date: new UntypedFormControl(null),
        purpose: new UntypedFormControl({ value: null, disabled: isDisabled }),
        notes: new UntypedFormControl({ value: null, disabled: isDisabled }),
        created_at: new UntypedFormControl(null),
      },
      {
        validators: [
          CustomValidators.dateRangeValidator("start_date", "end_date"),
        ],
      }
    );

    if (this.edit) {
      this.form.patchValue(this.assignment);

      this.searchHostCitiesControl.setValue(this.assignment.host_city);

      this.searchHomeCitiesControl.setValue(this.assignment.home_city);

      this.dictionariesService
        .cities(
          this.assignment.home_city.substring(0, 4),
          this.form.get("home_country").value
        )
        .subscribe(({ result }) => {
          this.homeCities = result;
        });

      this.dictionariesService
        .cities(
          this.assignment.host_city.substring(0, 4),
          this.form.get("host_country").value
        )
        .subscribe(({ result }) => {
          this.hostCities = result;
        });

      this.startDate = moment(this.assignment.start_date);
      this.endDate = moment(this.assignment.end_date);
    }

    this.form.get("home_country").valueChanges.subscribe((value) => {
      this.dictionariesService.cities("", value).subscribe(({ result }) => {
        this.homeCities = result;
      });
    });

    this.searchHomeCitiesControl.valueChanges.subscribe((value) => {
      if (value != "") {
        this.dictionariesService
          .cities(value, this.form.get("home_country").value)
          .subscribe(({ result }) => {
            this.homeCities = result;
          });
      }
    });

    this.form.get("host_country").valueChanges.subscribe((value) => {
      this.dictionariesService.cities("", value).subscribe(({ result }) => {
        this.hostCities = result;
      });
    });

    this.searchHostCitiesControl.valueChanges.subscribe((value) => {
      if (value != "") {
        this.dictionariesService
          .cities(value, this.form.get("host_country").value)
          .subscribe(({ result }) => {
            this.hostCities = result;
          });
      }
    });
  }

  public save() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (this.form.controls.host_office_id.value === 0) {
        this.form.controls.host_office_id.setValue(null);
      }
      if (this.form.controls.host_office_id.value === 0) {
        this.form.controls.home_office_id.setValue(null);
      }
      this.form.controls.client_id.setValue(this.clientId);

      this.form.controls.end_date.setValue(
        this.dateManagementService.formatDateMaterialInput(
          this.form.controls.end_date.value
        )
      );
      this.form.controls.start_date.setValue(
        this.dateManagementService.formatDateMaterialInput(
          this.form.controls.start_date.value
        )
      );
      if (this.edit) {
        this.isLoaded = false;

        this.assignmentsService.update(this.form.getRawValue()).subscribe({
          next: (assignment) => {
            this.dialogRef.close({ assignment: assignment });
          },
          error: () => {
            this.isLoaded = true;
          },
        });
      } else {
        this.isLoaded = false;

        this.assignmentsService.store(this.form.getRawValue()).subscribe({
          next: (assignment) => {
            this.dialogRef.close({ assignment: assignment });
          },
          error: () => {
            this.isLoaded = true;
          },
        });
      }
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public onHomeManagerChange(id: number) {
    this.onManagerChange(id, "home_manager");
  }

  public onHostManagerChange(id: number) {
    this.onManagerChange(id, "host_manager");
  }

  public onManagerChange(id: number, field: string) {
    const nameField = field + "_name";
    const emailField = field + "_email";
    if (id === 0) {
      this.form.controls[nameField].setValue("");
      this.form.controls[emailField].setValue("");
    } else {
      const manager = this.managers.find((item) => item.id === id);
      this.form.controls[nameField].setValue(manager.name);
      this.form.controls[emailField].setValue(manager.email);
    }
  }

  private getCountryList() {
    this.dictionariesService
      .getCountryList()
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((data) => {
        this.countries = data.result;
        this.filteredHomeCountries = data.result;
        this.filteredHostCountries = data.result;
      });
  }

  private filterHomeCountries() {
    this.homeCountriesFilterControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inputValue) => {
        this.filteredHomeCountries = this.countries.filter(
          (value) =>
            value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
      });
  }

  private filterExpats() {
    this.expatFilterControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inputValue) => {
        this.filteredExpats = this.expats.filter(
          (value) =>
            value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
      });
  }

  private filterHostCountries() {
    this.hostCountriesFilterControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((inputValue) => {
        this.filteredHostCountries = this.countries.filter(
          (value) =>
            value.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
      });
  }

  private getExpats() {
    this.expatsService
      .list(this.user.entity_id, this.clientId ?? this.data.clientId, {
        per_page: 1000,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.expats = data.result.items;
        this.filteredExpats = data.result.items;

        if (this.data.expatId) {
          this.form.get("expat_id").setValue(this.data.expatId);
          this.form.get("expat_id").disable();
        }

        this.isLoaded = true;
      });
  }

  private getTypes() {
    this.types$ = this.assignmentsService.types();
  }

  public get AssignmentTypeEnum() {
    return AssignmentTypeEnum;
  }

  onCalendarChangeStartDate(date: string): void {
    if (isNaN(Date.parse(date))) {
      return;
    }

    this.form.get("start_date").setValue(date);
    this.startDate = date;
  }

  onCalendarChangeEndDate(date: string): void {
    if (isNaN(Date.parse(date))) {
      return;
    }

    this.form.get("end_date").setValue(date);
    this.endDate = date;
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
