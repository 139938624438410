<button
  mat-icon-button
  #settingsTrigger="matMenuTrigger"
  [matMenuTriggerFor]="settingsMenu"
  (click)="$event.stopPropagation()"
  (menuClosed)="applyChanges()"
>
  <mat-icon svgIcon="settings"></mat-icon>
</button>
<mat-menu
  #settingsMenu="matMenu"
  xPosition="before"
  class="column-selection-options pb-2"
>
  <mat-selection-list #selection [hideSingleSelectionIndicator]="true">
    <mat-list-item
      *ngFor="let column of columns"
      (click)="$event.stopPropagation()"
    >
      <mat-list-option
        [checkboxPosition]="'before'"
        color="primary"
        [disabled]="column.readonly"
        [selected]="column.visible"
        [value]="column.name"
      >
        {{ column.label | translate }}
      </mat-list-option>
    </mat-list-item>
  </mat-selection-list>
  <button class="btn-primary" (click)="settingsTrigger.closeMenu()">
    {{ "LABEL.APPLY" | translate }}
  </button>
</mat-menu>
