<div class="dashboard-body-top" *ngIf="true">
  <!-- left -->
  <div *ngIf="true" class="fl-l big-title">
    {{ "GENERALS.MILESTONE-REPORTS" | translate }}
  </div>
  <!-- end left -->

  <div class="nowrap-btn-row">
    <div
      class="btn white-bg-border"
      (click)="exportItems('milestones'); isLoading = true"
    >
      <i class="icon-download"></i> {{ "GENERALS.EXPORT" | translate }}
    </div>
    <div class="btn white-bg-border" (click)="openMilestoneConfigModal()">
      {{ "GENERALS.CONFIGURE" | translate }}
    </div>
  </div>
  <!-- end right -->
</div>
<div class="float-right">
  <app-elements-on-page
    [perPage]="perPage"
    (getItem)="perPage = $event; navigateByUrl()"
  ></app-elements-on-page>
  <!-- end elem on page -->
</div>
<span
  class="gray total-items-filter justify-end w-full p-r-0"
  [ngClass]="{ 'm-t-15': !(availableFilters && availableFilters.length > 0) }"
>
  <div class="clear-all-filters" (click)="clearAllFilters()">
    {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
  </div>

  <span class="flex items-center"> | </span>
  {{ total }} {{ "GENERALS.RESULTS" | translate | lowercase }}
</span>
<div class="clear"></div>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <table
      *ngIf="!isLoading"
      class="milestone-report-table"
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
    >
      <thead>
        <tr>
          <th></th>
          <th class="border-right p-r-0 p-l-0">
            <span class="inline-flex items-center">
              <div class="positioner">
                {{ "GENERALS.CASE-ID" | translate }}
              </div>
              <app-report-filters
                class="text-right"
                *ngIf="!isFiltersLoaded"
                [availableFilters]="availableFilters"
                [key]="'cases_id'"
                [queryParams]="queryParams"
                (reportFilters)="navigateByParams($event)"
              ></app-report-filters>
              <app-loader-spinner-general
                class="loader-spinner"
                *ngIf="isFiltersLoaded"
                [loadingType]="LoadingType.SMALL_BUTTON"
              ></app-loader-spinner-general>
            </span>
          </th>
          <th *ngFor="let column of selectedColumns">
            <span class="inline-flex items-center">
              {{ column.label.split("ID")[0] | translate | titlecase }}
              {{
                column.label.indexOf("ID") > -1
                  ? "ID"
                  : ("" | translate | uppercase)
              }}
              <app-report-filters
                class="text-right"
                *ngIf="!isFiltersLoaded"
                [availableFilters]="availableFilters"
                [key]="column.id"
                [queryParams]="queryParams"
                (reportFilters)="navigateByParams($event)"
              ></app-report-filters>
              <app-loader-spinner-general
                class="loader-spinner"
                *ngIf="isFiltersLoaded"
                [loadingType]="LoadingType.SMALL_BUTTON"
              ></app-loader-spinner-general>
            </span>
          </th>
          <th *ngFor="let milestone of selectedMilestones">
            <span class="inline-flex items-center">
              <span class="milestone-header-name">
                {{ milestone.label | translate | titlecase }}
              </span>
              <app-report-filters
                *ngIf="!isFiltersLoaded"
                [milestoneFilter]="true"
                [key]="'milestone_date'"
                [type]="FilterType.DATE_RANGE"
                [milestoneId]="milestone.id"
                [queryParams]="queryParams"
                (reportFilters)="navigateByParams($event)"
              ></app-report-filters>
              <app-loader-spinner-general
                class="loader-spinner"
                *ngIf="isFiltersLoaded"
                [loadingType]="LoadingType.SMALL_BUTTON"
              ></app-loader-spinner-general>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="cursor-pointer"
          *ngFor="
            let item of items
              | paginate
                : {
                    itemsPerPage: perPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td [routerLink]="['/cases', item.case_id]" class="gray-pointer">
            <div class="tags" *ngIf="item.tags">
              <div *ngFor="let tag of item.tags" [ngClass]="['tag', tag.slug]">
                <mat-icon>warning</mat-icon>
              </div>
            </div>
          </td>
          <td
            [routerLink]="['/cases', item.case_id]"
            class="text-right border-right"
          >
            <a
              [routerLink]="['/cases', item.case_id]"
              class="font-weight-600 gray text-right"
              >#{{ item.case_id }}</a
            >
          </td>
          <td
            *ngFor="let column of selectedColumns"
            class="{{
              column.validation_type === 'string' ? '' : 'text-right'
            }}"
          >
            <div
              *ngIf="column.status"
              class="status"
              [appStatusStyle]="item[column.id] | lowercase"
            >
              {{ item[column.id] | notAvailable }}
            </div>
            <div *ngIf="!column.status">
              {{ item[column.id] | notAvailable }}
            </div>
          </td>
          <td
            *ngFor="let milestone of selectedMilestones"
            class="text-right {{
              item.milestones[milestone.id]?.end_date ? '' : ' milestone_date'
            }}"
          >
            {{
              item.milestones[milestone.id]?.forecast_end_date
                | date : "dd/MM/yyyy"
                | notApplicable
            }}
          </td>
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="10" class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
