<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<div class="white-box-wrapper" *ngIf="!isLoading">
  <div class="d-flex align-items-center header-milestones pt-2 pb-2">
    <div class="d-flex align-items-center" style="width: calc(100% - 182px)">
      <div class="title-milestones m-r-20 text-nowrap">
        {{ "GENERALS.MILESTONES" | translate }}
      </div>
      <div class="search-field row w-100">
        <mat-icon> search</mat-icon>
        <input
          matInput
          class="search-milestones"
          type="text"
          [placeholder]="'LABEL.MILESTONE_SEARCH_TEXT' | translate"
          [(ngModel)]="filter"
          (input)="filterResults(filter)"
        />
      </div>
    </div>
    <div class="btn-add-milestones w-auto" *canView="canCreate">
      <button class="btn-outline-none-primary" (click)="onAdd()">
        <mat-icon>add_circle_outline</mat-icon>
        {{ "GENERALS.ADD-MILESTONE" | translate }}
      </button>
    </div>
  </div>
  <div class="list-milestones">
    <div
      class="add-milestones-item d-flex justify-content-between align-items-center"
      *ngIf="isAddEnable"
    >
      <div class="name-milestones-item w-70">
        <div>
          <input
            matInput
            class="input-milestones-item w-100"
            [placeholder]="'LABEL.MILESTONE_ADD_TEXT' | translate"
            [(ngModel)]="milestoneName"
          />
        </div>
      </div>
      <div class="edit-button-row" *canView="canUpdate">
        <button class="pointer btn cancel-btn m-r-10" (click)="hideAddButton()">
          <mat-icon>highlight_off</mat-icon>
          <div class="text-cancel">{{ "GENERALS.CANCEL" | translate }}</div>
        </button>
        <button
          *canView="canDelete"
          class="pointer btn orange-bg save-btn"
          (click)="create(milestoneName)"
        >
          <img src="assets/icons/button-save-icon.svg" alt="" />
          <div class="text-save">{{ "GENERALS.SAVE" | translate }}</div>
        </button>
      </div>
    </div>
    <table
      mat-table
      [dataSource]="filterMilestoneList"
      *ngIf="filterMilestoneList.length > 0"
    >
      <ng-container matColumnDef="id" [sticky]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="id"
          class="text-right border-r w-1"
        >
          {{ "GENERALS.ID" | translate }}
        </th>
        <td mat-cell *matCellDef="let item" class="text-right border-r">
          <a class="text-secondary font-semibold text-right">
            #{{ item.id }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="name" [sticky]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="name"
          class="text-right border-r w-1"
        >
          {{ "GENERALS.NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              class="sub-menu-item"
              mat-menu-item
              *ngIf="isEditEnable !== item.id"
              (click)="onEdit(isEditEnable, item)"
            >
              <mat-icon>edit</mat-icon>
              <span>{{ "GENERALS.EDIT" | translate }}</span>
            </button>
            <button
              class="sub-menu-item"
              mat-menu-item
              (click)="delete(item.id)"
            >
              <mat-icon>delete</mat-icon>
              <span>{{ "GENERALS.DELETE" | translate }}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="loading">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <app-loader-spinner-general
            [loadingType]="LoadingType.TABLE"
          ></app-loader-spinner-general>
        </td>
      </ng-container>
      <ng-container matColumnDef="no-data">
        <td
          mat-footer-cell
          *matFooterCellDef
          [attr.colspan]="getColumns().length"
        >
          <div
            class="notification_row bg_light_blue my-2.5 !p-3 text-secondary"
          >
            {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: getColumns()"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['loading']"
        [hidden]="!isLoading"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['no-data']"
        [hidden]="filterMilestoneList?.length || isLoading"
      ></tr>
    </table>
    <div
      class="white-box-row medium-title no-items"
      *ngIf="filterMilestoneList.length === 0"
    >
      {{ "GENERALS.NO-ITEMS-FOUND" | translate }}
    </div>
  </div>
</div>
