<ng-container *ngIf="isLoading || loadingStatuses">
  <div class="loading-wrapper m-t-20">
    <app-loader-spinner-general
      class="w-profile"
      [loadingType]="LoadingType.INFO_BUTTONS"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      class="w-info"
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<div class="action-link-wrapper" *ngIf="!isLoading && !loadingStatuses">
  <button class="btn-outline-primary" (click)="cliendDetailsModal()">
    <mat-icon svgIcon="open-eye" class="btn-icon"></mat-icon>
    {{ "GENERALS.VIEW-BILLING-DETAILS" | translate }}
  </button>
  <div *ngFor="let status of statuses">
    <button (click)="openConfirmAction(status)" class="btn-outline-primary">
      <mat-icon
        svgIcon="{{ status.text | lowercase }}"
        class="btn-icon"
      ></mat-icon>
      {{ status.text }}
    </button>
  </div>
</div>

<div class="order-wrapper d-flex" *ngIf="!isLoading && !loadingStatuses">
  <div class="order-details rounded" *ngIf="order">
    <div class="flex flex-wrap mb-6">
      <div class="flex content-between items-center text-lg font-semibold">
        {{ "LABEL.ORDER" | translate }} #{{ order.identity_number }}
      </div>
    </div>
    <div class="flex flex-wrap mb-6">
      <div class="status" [appStatusStyle]="order.status_name | lowercase">
        {{ order.status_name | uppercase }}
      </div>
    </div>
    <div class="flex flex-wrap">
      <span class="text-secondary font-medium">
        {{ "GENERALS.TOTAL-ORDER-PRICE" | translate }}
      </span>
      <span class="display-field text-lg text-primary font-semibold">
        {{ order.amount | currency : order.currency_code }}
      </span>
    </div>
    <div class="flex flex-wrap">
      <span class="text-secondary block font-medium">
        {{ "PORTAL.PLACED_ON" | translate }}:
      </span>
      <span class="display-field">
        {{ order.created_at | date : "dd/MM/yyyy" }}
      </span>
    </div>
    <div class="flex flex-wrap">
      <span class="text-secondary block font-medium">
        {{ "GENERALS.CLIENT" | translate }}:
      </span>
      <span class="display-field">
        <a
          [routerLink]="['/clients', order.entity_id]"
          class="flex items-center"
          target="_blank"
        >
          <mat-icon svgIcon="external-link" class="!mr-1 !w-4 !h-4"></mat-icon>
          <span class="underline">{{ order.client.company_name }}</span>
        </a>
      </span>
    </div>
    <div class="flex flex-wrap">
      <span class="text-secondary block font-medium">
        {{ "PORTAL.ASSIGNED-SERVICES" | translate }}:
      </span>
      <span
        class="{{
          helper.remainingExpatsToAssign(order)
            ? 'text-primary'
            : '!text-success'
        }} display-field"
        >{{ helper.assignedExpatsOnOrder(order) }} /
        {{ helper.expatsOnOrder(order) }}</span
      >
    </div>
  </div>
  <div class="expat-cards" *ngIf="!isLoading && !loadingStatuses">
    <div class="card border-none !rounded" *ngFor="let offer of order.details">
      <div class="flex flex-wrap">
        <div class="flex w-full">
          <div class="w-120">
            <a *ngIf="!offer?.primary_image_path.length">
              <img
                class="item-img"
                ngSrc="assets/images/footer-img.jpg"
                alt="img"
                height="120"
                width="120"
              />
            </a>
            <a *ngIf="offer?.primary_image_path.length">
              <img
                class="item-img"
                ngSrc="{{ offer?.primary_image_path }}"
                alt="img"
                height="120"
                width="120"
              />
            </a>
          </div>
          <div class="flex ml-3 w-rest">
            <div class="w-2/3">
              <div class="text-lg font-semibold mb-1.5">
                {{ offer.offer_name }}
              </div>
              <div class="flex font-medium mb-1.5">
                <span class="text-secondary mr-3">
                  {{ "GENERALS.PRICE-PER-UNIT" | translate }}:
                </span>
                <span>
                  {{ offer.unit_price | currency : offer.currency_code }}
                </span>
              </div>
              <div class="flex font-medium mb-1.5">
                <span class="text-secondary mr-3">
                  {{ "GENERALS.QUANTITY" | translate }}:
                </span>
                <span
                  class="{{
                    offer.expats.length === offer.quantity
                      ? '!text-success'
                      : 'text-primary'
                  }}"
                  >{{ offer.expats.length }} / {{ offer.quantity }}</span
                >
              </div>
              <div class="flex font-medium mb-1.5">
                <span class="text-secondary mr-3">
                  {{ "GENERALS.TOTAL-PRICE" | translate }}:
                </span>
                <span class="font-semibold">
                  {{ offer.total_price | currency : offer.currency_code }}
                </span>
              </div>
            </div>
            <div class="w-1/3">
              <app-questions-button
                class="pl-3 mb-3 w-full flex flex-wrap justify-end"
                [offerId]="offer.offer_id"
                [orderId]="offer.order_id"
                [answerStatusId]="offer.answer_status_id"
              ></app-questions-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full mt-6">
        <button
          *ngIf="
            offer.quantity > offer.expats.length &&
            order.entity_type !== 'expat'
          "
          class="py-2 px-6 border flex items-center justify-center font-medium text-primary rounded hover:bg-gray-light w-full text-sm"
          (click)="assignExpat(offer)"
        >
          <mat-icon svgIcon="user-plus" class="btn-icon"></mat-icon>
          {{ "PORTAL.ADD-EXPATS" | translate }}
        </button>
      </div>

      <div *ngIf="offer.expats" class="mt-3">
        <div *ngFor="let expat of offer.expats; let first = first">
          <app-order-assignment-details
            [order]="order"
            [orderItem]="offer"
            [orderExpat]="expat"
            [isFirst]="first"
            (expatDeleted)="getOrderById(order.identity_number)"
            class="w-full block d-block p-0"
            (caseCreated)="caseCreated()"
          >
          </app-order-assignment-details>
          <div *ngIf="!expat.assignment_id" class="expat-holder">
            <table class="w-100">
              <thead>
                <tr>
                  <th class="fw-700">
                    {{ "EXPENSES.NAME" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ expat.expat.user_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
