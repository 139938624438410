<div class="details-body-wrapper">
  <div windowScrollDashboardBodyTop class="dashboard-body-top">
    <!-- right -->
    <div class="button-bar">
      <button class="btn-outline-primary" (click)="export()">
        <mat-icon svgIcon="export" class="btn-icon"></mat-icon>
        {{ "GENERALS.EXPORT" | translate }}
      </button>
      <button class="btn-primary" (click)="createOrEdit()">
        <mat-icon svgIcon="receipt" class="btn-icon"></mat-icon>
        {{ "PAGES.ORDERS.ORDER_QUOTES.ADD_QUOTE" | translate }}
      </button>
    </div>
    <!-- end right -->
  </div>
  <!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.FILTER"
  ></app-loader-spinner-general>

  <!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
  <div class="dashboard-body-action" *ngIf="!isLoading">
    <!-- left -->
    <div class="float-left w-full p-0 advanced-search-wrapper">
      <app-filters
        [availableFilters]="availableFilters"
        [activeFilters]="filters"
        (search)="textChange($event)"
        (filter)="filters = $event; navigateByUrl()"
        [total]="total"
        searchPlaceholder="Service quotes"
        (changePerPage)="perPage = $event; navigateByUrl()"
        (eventClearFilters)="clearFilters(); navigateByUrl()"
      >
      </app-filters>
    </div>
    <!-- end left -->
  </div>
  <!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

  <!-- table bulk action -->
  <app-bulk-actions
    *ngIf="showBulkActions"
    (selectionCleared)="showBulkActions = !showBulkActions"
    (onClick)="execBulkAction($event)"
    [selection]="selection"
    [extraActions]="actions"
  ></app-bulk-actions>
  <!-- end table bulk action -->

  <!-- table -->
  <div class="details-body-wrapper">
    <div class="table-wrapper">
      <ng-container *ngIf="!isMobileDevice; else responsiveTable">
        <div class="table-responsive" *ngIf="!isLoading">
          <table
            matSort
            (matSortChange)="sortData($event)"
            [matSortActive]="active"
            [matSortDirection]="direction"
          >
            <thead>
              <tr>
                <ng-container matColumnDef="select">
                  <th class="w-1">
                    <mat-checkbox
                      (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [disableRipple]="true"
                    >
                    </mat-checkbox>
                  </th>
                </ng-container>
                <th mat-sort-header="id" class="text-right border-r w-1">
                  {{ "GENERALS.ID" | translate }}
                </th>
                <th mat-sort-header="offer_id" class="text-right">
                  {{ "GENERALS.SERVICE_ID" | translate }}
                </th>
                <th mat-sort-header="service_name">
                  {{ "GENERALS.SERVICE-NAME" | translate }}
                </th>
                <th class="text-right" mat-sort-header="unit_price">
                  {{ "LABEL.UNIT_PRICE" | translate }}
                </th>
                <th class="text-right" mat-sort-header="expires_at">
                  {{ "LABEL.VALID_UNTIL" | translate }}
                </th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody *ngIf="isLoading">
              <tr>
                <td colspan="100">
                  <app-loader-spinner-general
                    [loadingType]="LoadingType.TABLE"
                  ></app-loader-spinner-general>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                *ngFor="
                  let item of items
                    | paginate
                      : {
                          itemsPerPage: perPage,
                          currentPage: p,
                          totalItems: total
                        };
                  let i = index
                "
              >
                <td>
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (keyup)="$event.stopPropagation()"
                    (change)="$event ? changeSelection(item) : null"
                    [checked]="selection.isSelected(item)"
                    [disableRipple]="true"
                  >
                  </mat-checkbox>
                </td>
                <td class="text-right border-right gray font-weight-600">
                  #{{ item.id }}
                </td>
                <td class="text-right">
                  <a
                    [routerLink]="['/service-offers', item.offer_id]"
                    target="_blank"
                    [href]="'/service-offers' + item.offer_id"
                    class="link-secondary"
                  >
                    #{{ item.offer_id }}
                  </a>
                </td>
                <td>
                  <a
                    [routerLink]="['/service-offers', item.offer_id]"
                    target="_blank"
                    class="link-secondary"
                  >
                    {{ item.service_name }}
                  </a>
                </td>
                <td class="text-right">
                  {{ item.unit_price | currency : item.currency_code }}
                </td>
                <td class="text-right">
                  <span *ngIf="!calendar.isExpired(item.expires_at)">
                    {{ item.expires_at | date : "dd/MM/yyyy" }}
                  </span>
                  <span
                    class="text-primary"
                    *ngIf="calendar.isExpired(item.expires_at)"
                  >
                    {{ "RFQ.EXPIRED" | translate }}
                  </span>
                </td>

                <!-- table cel with action -->
                <td class="text-right">
                  <div class="box-actions">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="quoteMenu"
                      class="btn-outline-none-primary items-end"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #quoteMenu="matMenu" xPosition="before">
                      <button
                        mat-menu-item
                        class="uppercase"
                        (click)="createOrEdit(item)"
                      >
                        {{ "GENERALS.EDIT" | translate }}
                      </button>
                      <button
                        mat-menu-item
                        class="uppercase"
                        (click)="remove(item)"
                      >
                        {{ "GENERALS.DELETE" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </tr>

              <tr *ngIf="items?.length === 0">
                <td colspan="100" class="text-secondary">
                  <div class="notification_row bg_light_blue my-2.5 p-3">
                    {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-template #responsiveTable>
        <!-- responsive table -->
        <div class="resposive-table-all-check">
          <ng-container *ngIf="isLoading">
            <app-loader-spinner-general
              [loadingType]="LoadingType.TABLE"
            ></app-loader-spinner-general>
          </ng-container>
          <ng-container *ngIf="!isLoading">
            <div class="resposive-table-wrapper" *ngFor="let item of items">
              <div class="responsive-table-row">
                <div class="responsive-table-header-item"></div>
                <div class="responsive-table-data-item ta-r">
                  <button
                    id="add-to-cart"
                    class="btn orange-bg"
                    (click)="remove(item)"
                  >
                    <i class="icon-trash-empty"></i>
                  </button>
                </div>
              </div>
              <div class="responsive-table-row">
                <div class="responsive-table-header-item">#</div>
                <div class="responsive-table-data-item">{{ item.id }}</div>
              </div>
              <div class="responsive-table-row">
                <div class="responsive-table-header-item">
                  {{ "LABEL.OFFER_ID" | translate }}
                </div>
                <a
                  [routerLink]="['/service-offers', item.offer_id]"
                  target="_blank"
                  class="action-link-orange-sm"
                  >{{ item.offer_id }}</a
                >
              </div>
              <div class="responsive-table-row">
                <div class="responsive-table-header-item">
                  {{ "FORMS.OFFER_TITLE" | translate }}
                </div>
                <div class="responsive-table-data-item">
                  <a
                    [routerLink]="['/service-offers', item.offer_id]"
                    target="_blank"
                    class="action-link-orange-sm"
                    >{{ item.service_name }}</a
                  >
                </div>
              </div>
              <div class="responsive-table-row">
                <div class="responsive-table-header-item">
                  {{ "LABEL.UNIT_PRICE" | translate }}
                </div>
                <div class="responsive-table-data-item">
                  {{ item.unit_price }}
                </div>
              </div>
              <div class="responsive-table-row">
                <div class="responsive-table-header-item">
                  {{ "LABEL.VALID_UNTIL" | translate }}
                </div>
                <div class="responsive-table-data-item">
                  {{ item.expires_at | date : "dd/MM/yyyy" }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- end responsive table -->
      </ng-template>

      <!-- paginator -->
      <div class="paginator">
        <pagination-controls
          (pageChange)="navigateByUrl($event)"
          previousLabel=""
          nextLabel=""
        ></pagination-controls>
      </div>
    </div>
  </div>
  <!-- end table -->
</div>
