import { Component, Input, ViewChild } from "@angular/core";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { ColumnDefinition } from "../../../../_interfaces/column-definition.interface";

@Component({
  selector: "app-column-selection",
  templateUrl: "./column-selection.component.html",
  styleUrls: ["./column-selection.component.scss"],
})
export class ColumnSelectionComponent {
  @Input() columns: ColumnDefinition[];

  @ViewChild("selection")
  selection: MatSelectionList;

  applyChanges() {
    this.columns.forEach((column: ColumnDefinition) => {
      const isSelected = this.selection.selectedOptions.selected.some(
        (field: MatListOption) => {
          return field.value === column.name;
        }
      );
      column.visible = isSelected;
    });
  }
}
